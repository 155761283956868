import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../auth/firebaseConnection";
import Sidebar from '../../components/SideBar';
import { getAuth } from "firebase/auth";
import './tarefas.css';

const Quadro = () => {
  const { quadroId } = useParams();
  const navigate = useNavigate();
  const [listas, setListas] = useState([]);
  const [nomeLista, setNomeLista] = useState('');
  const [tarefaInfo, setTarefaInfo] = useState({ nome: '', descricao: '', listaId: '' });

  // Carrega as listas e tarefas
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "quadros", quadroId, "listas"),
      (snapshot) => {
        const listaDados = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          tarefas: [],
        }));
        setListas(listaDados);
      },
      (error) => {
        console.error("Erro ao carregar as listas:", error);
      }
    );

    return () => unsubscribe();
  }, [quadroId]);

  // Criar uma nova lista
  const criarLista = async () => {
    if (nomeLista.trim() === '') {
      alert('O nome da lista é obrigatório!');
      return;
    }

    try {
      await addDoc(collection(db, "quadros", quadroId, "listas"), {
        nome: nomeLista,
        ordem: listas.length + 1,
      });
      setNomeLista('');
      alert('Lista criada com sucesso!');
    } catch (error) {
      console.error("Erro ao criar lista:", error);
    }
  };

  // Criar uma nova tarefa
  const criarTarefa = async () => {
    const auth = getAuth();
    const { nome, descricao, listaId } = tarefaInfo;
    if (!nome || !listaId) {
      alert('Nome da tarefa e lista são obrigatórios!');
      return;
    }
  
    try {
      await addDoc(collection(db, "quadros", quadroId, "listas", listaId, "tarefas"), {
        nome,
        descricao,
        status: 'Pendente',
        criadorId: auth.currentUser.uid, // Adiciona o ID do criador
      });
      setTarefaInfo({ nome: '', descricao: '', listaId: '' });
      alert('Tarefa criada com sucesso!');
    } catch (error) {
      console.error("Erro ao criar tarefa:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="container container-quadros">
        <div className="quadro">
          <button className="voltar" onClick={() => navigate('/quadros')}>
            &larr; Voltar aos Quadros
          </button>
          <h1>Detalhes do Quadro</h1>
          <div className="listas">
            {listas.map(lista => (
              <div key={lista.id} className="lista">
                <h2>{lista.nome}</h2>
                <div className="tarefas">
                  {/* Aqui renderizamos as tarefas */}
                  {(lista.tarefas || []).map(tarefa => (
                    <div key={tarefa.id} className="tarefa">
                      <h3>{tarefa.nome}</h3>
                      <p>{tarefa.descricao}</p>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => setTarefaInfo({ ...tarefaInfo, listaId: lista.id })}
                >
                  Adicionar Tarefa
                </button>
              </div>
            ))}
          </div>
          <div className="criar-lista">
            <input
              type="text"
              placeholder="Nome da Lista"
              value={nomeLista}
              onChange={(e) => setNomeLista(e.target.value)}
            />
            <button onClick={criarLista}>Criar Nova Lista</button>
          </div>
          {tarefaInfo.listaId && (
            <div className="modal-tarefa">
              <h2>Criar Tarefa</h2>
              <input
                type="text"
                placeholder="Nome da Tarefa"
                value={tarefaInfo.nome}
                onChange={(e) => setTarefaInfo({ ...tarefaInfo, nome: e.target.value })}
              />
              <textarea
                placeholder="Descrição"
                value={tarefaInfo.descricao}
                onChange={(e) => setTarefaInfo({ ...tarefaInfo, descricao: e.target.value })}
              />
              <button onClick={criarTarefa}>Salvar</button>
              <button onClick={() => setTarefaInfo({ nome: '', descricao: '', listaId: '' })}>
                Cancelar
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Quadro;