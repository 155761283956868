import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../auth/firebaseConnection";
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SideBar';
import './tarefas.css';
import { getAuth } from "firebase/auth";

const Quadros = () => {
  const [quadros, setQuadros] = useState([]);
  const [nomeQuadro, setNomeQuadro] = useState('');

  useEffect(() => {
    const carregarQuadros = async () => {
      const querySnapshot = await getDocs(collection(db, "quadros"));
      const listaQuadros = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setQuadros(listaQuadros);
    };

    carregarQuadros();
  }, []);

  const criarQuadro = async () => {
    const auth = getAuth();
    const idUsuario = auth.currentUser?.uid;
  
    if (!idUsuario) {
      alert("Você precisa estar autenticado para criar um quadro.");
      return;
    }
  
    if (nomeQuadro.trim() === '') {
      alert('O nome do quadro é obrigatório!');
      return;
    }
  
    try {
      const docRef = await addDoc(collection(db, "quadros"), {
        nome: nomeQuadro,
        idCriador: idUsuario,
        dataDeCriacao: new Date(),
      });
  
      // Atualiza o estado local adicionando o novo quadro
      setQuadros([...quadros, { id: docRef.id, nome: nomeQuadro, idCriador: idUsuario }]);
  
      setNomeQuadro('');
      alert('Quadro criado com sucesso!');
    } catch (error) {
      console.error("Erro ao criar o quadro:", error);
      alert("Erro ao criar o quadro. Verifique as permissões do Firestore.");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="container">
        <div className="tarefas">
          <h1>Meus Quadros</h1>
          <div className="lista-quadros">
            {quadros.map(quadro => (
              <div key={quadro.id} className="quadro-item">
                <Link to={`/quadro/${quadro.id}`}>
                  <h2>{quadro.nome}</h2>
                </Link>
              </div>
            ))}
          </div>
          <div className="criar-quadro">
            <input
              type="text"
              placeholder="Nome do Quadro"
              value={nomeQuadro}
              onChange={(e) => setNomeQuadro(e.target.value)}
            />
            <button onClick={criarQuadro}>Criar Novo Quadro</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quadros;