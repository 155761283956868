import React, { useState, useEffect } from 'react';
import './sidebar.css';
import LogoP from '../../assets/img/PBranco.png';
import { signOut, getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faUsers, faUserPlus, faRightFromBracket, faBars, faBarsStaggered, faHouse, faCalendar, faTable, faPenToSquare, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import isUserMaster from '../../routes/isUserMaster'

function Sidebar() {
  const auth = getAuth();
  const user = auth.currentUser;
  const userEmail = user.email;
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [userMaster, setUserMaster] = useState(false);

  useEffect(() => {
    const checkUserMaster = async () => {
      const isMaster = await isUserMaster();
      setUserMaster(isMaster);
    };

    checkUserMaster();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth < 768); // Atualiza o estado com base no tamanho da janela
    };

    window.addEventListener("resize", handleResize); // Adiciona o listener de redimensionamento

    // Define o estado inicial no carregamento
    handleResize();

    return () => window.removeEventListener("resize", handleResize); // Limpa o listener no desmontar
  }, []);

  async function deslogar(e) {
    e.preventDefault();
    await signOut(auth)
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleItem = (itemId) => {
    if (activeItem === itemId) {
      setActiveItem(null);
    } else {
      setActiveItem(itemId);
    }
  };


  return (
    <div className={`sidebar ${isOpen ? '' : 'open'}`}>
      <div className="logo-details">
        <img src={LogoP} alt="Logo" />
        <div className="logo_name">POSITIVE</div>
        <FontAwesomeIcon
          icon={isOpen ? faBars : faBarsStaggered}
          onClick={toggleSidebar}
          id="btn"
        />
    </div>
      <ul className="nav-list">
        <li>
          <h3>Olá: <span>{userEmail}</span></h3>
        </li>
        <li>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHouse} className='i' />
            <span className="links_name">Home</span>
          </Link>
          <span className="toolti">Home</span>
        </li>
        <li>
          <div onClick={() => toggleItem(1)}>
            <FontAwesomeIcon icon={faPenToSquare} className='i' />
            <span className="links_name">Demandas</span>
            <FontAwesomeIcon
              icon={activeItem === 1 ? faChevronUp : faChevronDown}
              id='hide-icon'
            />
          </div>
          <span className="toolti">Demandas</span>
          {activeItem === 1 && (
            <ul id='div-map'>
              <li>
                <Link id='a-map' to="/demandas">Buscar Demanda</Link>
              </li>
              {userMaster && (
                <>
                  <li>
                    <Link id='a-map' to="/nova-demanda">Cadastrar Demanda</Link>
                  </li>
                </>
              )}
            </ul>
          )}
        </li>
        <li>
          <Link to="/atendimento">
            <FontAwesomeIcon icon={faTable} className='i' />
            <span className="links_name">Atendimento</span>
            <span className="toolti">Atendimento</span>
          </Link>
        </li>
        <li>
          <Link to="/contas">
            <FontAwesomeIcon icon={faHandHoldingDollar} className='i' />
            <span className="links_name">Contas</span>
            <span className="toolti">Contas</span>
          </Link>
        </li>
        <li>
          <Link to="/agendamento">
            <FontAwesomeIcon icon={faCalendar} className='i' />
            <span className="links_name">Agendamento</span>
            <span className="toolti">Agendamento</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/quadros">
            <FontAwesomeIcon icon={faCalendar} className='i' />
            <span className="links_name">Tarefas</span>
            <span className="toolti">Tarefas</span>
          </Link>
        </li> */}

        {userMaster && (
          <>
            <li>
              <Link to="/cliente">
                <FontAwesomeIcon icon={faUsers} className='i' />
                <span className="links_name">Clientes</span>
                <span className="toolti">Clientes</span>
              </Link>
            </li>
          </>
        )}
        {userMaster && (
          <>
            <li>
              <Link to="/register">
                <FontAwesomeIcon icon={faUserPlus} className='i' />
                <span className="links_name">Cadastrar Usuário</span>
                <span className="toolti">Cadastrar usuário</span>
              </Link>
            </li>
          </>
        )}
        <li className="profile">
          <button id="log_out"><FontAwesomeIcon icon={faRightFromBracket} onClick={deslogar} /></button>
          <span>Sair</span>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
